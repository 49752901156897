import React from 'react';
import Navbar from 'cccisd-header';
import style from './style.css';
import Shopping from 'cccisd-icons/cart';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const Env = window.cccisd.env;
const wpUrl = Env.wpUrl || Appdefs.app.wpUrl;

export default () => {
    const actingRole = Fortress.user.acting.role.handle;
    const primaryNavLinks = [
        { url: wpUrl, label: 'Home' },
        { url: wpUrl + '/index.php/our-services', label: 'Our Services' },
        { url: wpUrl + '/index.php/about-us/', label: 'About Us' },
        { url: '/interventions', label: 'My Courses' },
        { url: '/resources_public', label: 'Resources' },
        { url: wpUrl + '/index.php/cart/', label: '', icon: Shopping },
    ];

    const secondaryNavLinks = [
        { url: '/manage', label: 'Manage' },
        { url: '/quest', label: 'Data' },
    ];

    return (
        <>
            <div className={style.fontName}>
                {actingRole === 'uberadmin' ? (
                    <Navbar
                        containerClassName="container-fluid"
                        wrapperClassName={style.wrapper}
                        logoClassName={style.logo}
                        showAlertAction
                        primaryNavLinks={primaryNavLinks}
                        secondaryNavLinks={secondaryNavLinks}
                        logoLink={wpUrl}
                    />
                ) : (
                    <Navbar
                        containerClassName="container-fluid"
                        wrapperClassName={style.wrapper}
                        logoClassName={style.logo}
                        showAlertAction
                        primaryNavLinks={primaryNavLinks}
                        logoLink={wpUrl}
                    />
                )}
            </div>
        </>
    );
};
